
import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/header.vue"; // @ is an alias to /src
import Sidebar from "@/components/left-sidebar.vue"; // @ is an alias to /src
import Loader from "@/components/loader.vue"; // @ is an alias to /src
import HRMService from "../../../services/hrm-service";
import BuiltinService from "../../../services/buitin-service";
import FilterComponent from "@/components/sidebar-employee-filter.vue";
import FilterService from "../../../services/filter-service";

@Component({
  components: {
    Header,
    Sidebar,
    Loader,
    FilterComponent,
  },
})
export default class Document extends Vue {
  loading = true;
  public filter = null;
  public filterOn = [];
  salary: any = {};
  public fields = [
    { key: "employee_title", sortable: false },
    { key: "name", sortable: false },
    { key: "type", sortable: false },
    { key: "document", sortable: false },
    {
      key: "created_at",
      sortable: true,
      formatter: (value: any) => {
        if (value) {
          const dateTime = new Date(value);
          const formattedDate = dateTime
            .toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })
            .replace(/\//g, "-"); // Format as dd-mm-yyyy
          const formattedTime = dateTime.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          }); // Format as HH:MM:SS
          return `${formattedDate} / ${formattedTime}`;
        }
        return "";
      },
    },
    { key: "comments", sortable: false },
  ];
  public columns = [
    { label: "Title", field: "employee_title" },
    { label: "Name", field: "name" },
    { label: "Type", field: "type" },
    { label: "Document", field: "document" },
    {
      label: "Created At",
      field: "created_at",
      format: (value: any) => {
        const date = new Date(value);
        const formattedDate = date.toLocaleDateString();
        const formattedTime = date.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        });
        return `${formattedDate} / ${formattedTime}`;
      },
    },
    { label: "Comments", field: "comments" },
  ];
  public widths = [100, 70, 70, 70, 70, 80];

  public selected_expat = "all";
  public option_expat = [
    { text: "All Employees", value: "all" },
    { text: "Saudi", value: "saudi" },
    { text: "Non Saudi", value: "Non saudi" },
  ];

  public option_dept: any = [];
  public selected_dept = 0;
  public option_emptype: any = [];
  public selected_emptype = 0;
  public option_branch: any = [];
  public selected_branch = 0;
  public option_bloodgroup: any = [];
  public selected_bloodgroup = 0;
  public selected_effective_date_from = "";
  public selected_effective_date_to = "";
  import_response = "";
  public selected_items = [];
  public items = [];
  public currentPage = 1;
  get rows() {
    return this.selected_items.length;
  }

  public import_file = null;
  edit = false;

  retrieve() {
    this.loading = true;
    HRMService.getAll_documents()
      .then((response) => {
        this.items = response.data;
        this.selected_items = this.items;
        // console.log(response.data)
        this.loading = false;
        // console.log(response.data);
      })
      .catch((e) => {
        this.loading = false;
        console.log(e);
      });
  }

  refreshList() {
    this.get_filters();
    this.retrieve();
  }

  expatchange() {
    if (this.selected_expat == "all") {
      this.selected_items = this.items;
    } else {
      this.selected_items = this.items.filter(
        (x) => x["expats"] == this.selected_expat
      );
    }
  }

  bloodgroupchange() {
    if (this.selected_bloodgroup == 0) {
      this.selected_items = this.items;
    } else {
      let selectedoption = this.option_bloodgroup.filter(
        (y: any) => y["value"] == this.selected_bloodgroup
      );
      this.selected_items = this.items.filter(
        (x) => x["blood_group"] == selectedoption[0]["text"]
      );
    }
  }

  effectivedateto() {
    // console.log(this.selected_effective_date_to)
    if (this.selected_effective_date_to) {
      this.selected_items = this.items.filter(
        (x) => x["employment_effective_date"] < this.selected_effective_date_to
      );
    }
  }
  effectivedatefrom() {
    if (this.selected_effective_date_from) {
      this.selected_items = this.items.filter(
        (x) =>
          x["employment_effective_date"] < this.selected_effective_date_from
      );
    }
  }

  onFiltered(filteredItems: any) { }

  mounted() {
    this.retrieve();
    this.get_filters();
  }

  get_filters() {
    this.loading = true;
    BuiltinService.getallbuiltin()
      .then((response) => {
        this.option_dept = [];
        let dept: any = response.data["department"];
        this.option_dept = dept.map((item: any) => {
          return { value: item.id, text: item.title };
        });
        this.option_dept.push({ value: 0, text: "Please select an option" });

        this.option_bloodgroup = [];
        let bloodgroup = response.data["blood_group"];
        this.option_bloodgroup = bloodgroup.map((item: any) => {
          return { value: item.id, text: item.title };
        });
        this.option_bloodgroup.push({
          value: 0,
          text: "Please select an option",
        });

        this.option_emptype = [];
        let employment_type = response.data["employment_type"];
        this.option_emptype = employment_type.map((item: any) => {
          return { value: item.id, text: item.title };
        });
        this.option_emptype.push({ value: 0, text: "Please select an option" });

        this.option_branch = [];
        let branch = response.data["branch"];
        this.option_branch = branch.map((item: any) => {
          return { value: item.id, text: item.title };
        });
        this.option_branch.push({ value: 0, text: "Please select an option" });
        this.loading = false;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  // onRowClicked(item: any, index: any, event: any) {
  //   this.$router.push({
  //     name: "Create-Salary",
  //     params: { id: item.id.toString() },
  //   });

  // }
  filter_fields = [
    {
      type: "text",
      label: "Employee Title",
      name: "title",
      value: "",
      param_name: "doc_title",
      filter_type: "simple",
    },
    {
      type: "text",
      label: "Name",
      name: "document_name",
      value: "",
      param_name: "doc_name",
      filter_type: "simple",
    },
    {
      type: "text",
      label: "Type",
      name: "document_type",
      value: "",
      param_name: "doc_type",
      filter_type: "simple",
    },
    {
      type: "date",
      label: "Created At",
      name: "document_created_at",
      value: "",
      param_name: "document_created_at",
      filter_type: "simple",
    },
  ];

  handleFilterChange(element: any) {
    if (element.filter_type === "simple") {
      interface SimpleObj {
        [key: string]: any;
      }
      const simpleFilteredObj: SimpleObj = {};
      this.filter_fields.forEach((filter) => {
        simpleFilteredObj[filter.param_name] = filter.value;
      });
      this.loading = true;
      FilterService.getAll(simpleFilteredObj).then((data) => {
        if (
          data.data.employees_doc !== undefined &&
          data.data.employees_doc !== null
        ) {
          this.selected_items = data.data.employees_doc;
          this.loading = false;
        } else {
          this.retrieve();
        }
      });
    }
  }
  updateFilterFields(fields: any[]) {
    this.filter_fields = fields;
  }
  refreshDateFeild(element: any) {
    // this.retrieve();
    element.range = [];
  }
  refreshFilters() {
    for (let field of this.filter_fields) {
      field.value = "";
    }
    this.retrieve();
  }
}
